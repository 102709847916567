<template>
  <div class="data-box">
    <div class="data-box-head">
      感谢您对我们活动的支持与配合，<br/>
      请您为展示二维码的人员办理入住或离店手续。
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <span class="span-head">住宿安排负责人</span>
          <span class="span-name align-right">{{ livePerson.name }}</span>
          <a :href="'tel:' + livePerson.phone" class="iconfont icon-dianhua1" v-show="livePerson.phone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in liveKeyValueList" :key="index">
          <span class="span-key span-line">{{ item.key }}</span>
          <span class="span-value">{{ item.value ? item.value : "无" }}</span>
        </div>
      </div>
      <div class="body-div">
        <div v-show="showType === 2">
          <div class="body-div-item">
            <div class="span-key span-line">到店时间</div>
            <div class="span-value">{{$tool.dateTimeFormat(new Date())}}</div>
          </div>
          <div class="body-div-item">
            <div class="span-key">安排房间</div>
            <div class="span-input">
              <van-field
                  v-model="sendInfo.roomEnergyFood"
                  placeholder="请输入安排的房间号"
              >
              </van-field>
            </div>
          </div>
        </div>
        <div v-show="showType === 3">
          <div class="body-div-item">
            <div class="span-key span-line">入住房间</div>
            <div class="span-value">{{sendInfo.roomEnergyFood}}房</div>
          </div>
          <div class="body-div-item">
            <div class="span-key span-line">离店时间</div>
            <div class="span-value">{{$tool.dateTimeFormat(new Date())}} 共{{getDay}}晚</div>
          </div>
        </div>
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">备注说明</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.note"
                rows="3"
                type="textarea"
                maxlength="200"
                placeholder="请输入备注说明"
                show-word-limit
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">验证码</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.code"
                maxlength="4"
                placeholder="请输入领取人手机号码后4位"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">经办人</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePerson"
                placeholder="请输入经办人"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">联系电话</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePhone"
                maxlength="11"
                placeholder="请输入联系电话"
            >
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button round block type="info" @click="onSubmit">提交</van-button>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  energyWashRoomFoodRegister,
  wechatWhetherBindPhone
} from "@/api/index";

export default {
  name: "parLive_details",
  data() {
    return {
      showType: 2,
      livePerson: {
        name: "",
        phone: ""
      },
      liveKeyValueList: [
        { key: "使用单位", value: "" },
        { key: "人员姓名", value: "" },
        { key: "身份证号", value: "" },
        { key: "入住酒店", value: "" },
        { key: "入住房型", value: "" },
        { key: "住店日期", value: "" },
        { key: "入住要求", value: "" }
      ],
      sendInfo: {
        actId: 0, // 关联活动id
        activityId: 0, // 关联消费对象id
        handlePerson: "", // 经办人(发放人)姓名
        handlePhone: "", // 经办人(发放人)电话
        hotelDiningId: 0, // 入住酒店id
        roomEnergyFood: "", // 房间号
        code: "", // 验证码手机后四位
        num1: 0, // 房型id
        note: "", // 备注、说明
        type: 0 // 0-开始住宿 1-退房办理 2-能耗消费 3-车辆清洁 4-派餐领取餐食 5-堂食时取餐
      },
      chargePhone: "" // 保存当前住宿人员电话
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  computed: {
    getDay() {
      let that = this;
      // that.liveKeyValueList[5].value // 入住日期
      let time1 = new Date(that.liveKeyValueList[5].value).getTime()
      let time2 = new Date(that.$tool.dateTimeFormat(new Date())).getTime()
      let time3 = time2 - time1
      let days = time3 / (1000*3600*24);
      let hours = (time3 % (1000 * 3600 * 24)) / (1000 * 60 * 60);
      if(days >= 1 && hours > 0) {
        days++
      }
      return Math.ceil(days)
    }
  },
  methods:{
    initData(){
      let that = this;
      let id = that.$route.query.id;
      that.sendInfo.actId = that.$store.getters.getActivityId;
      that.sendInfo.activityId = that.$store.getters.getActObjId;

      let params = {
        ayId: that.sendInfo.activityId, // 人员ID
        companyId: that.$store.getters.getCompanyId // 公司ID
      }
      that.$tool.getActObjInfo(id, (data) => {
        console.log(data.stayStatus)
        if (data.stayStatus == 2) {
          that.$router.push({
            path: `/par_success`,
            query: {
              type: 3
            }
          })
        }
        that.chargePhone = data.chargePhone // 保存当前住宿人员电话
        if (data.stayStatus == 0 || data.stayStatus == null) {
          that.showType = 2
          that.sendInfo.type = 0
        }
        if (data.stayStatus == 1) {
          that.sendInfo.type = 1
          that.showType = 3
        }
        that.liveKeyValueList[0].value = data.postName;
        that.liveKeyValueList[2].value = data.idResidueSite; // 身份证
        data.activitySets.forEach(item => {
          if(item.relateType === 2){
            that.livePerson.name = item.chargeName;
            that.livePerson.phone = item.chargePhone;
          }
          if(item.relateType === 14){
            that.liveKeyValueList[1].value = item.chargeName;
            that.liveKeyValueList[1].phonr = item.chargePhone;
          }

          if(item.relateType === 9){
            let obj = JSON.parse(item.noteNameDateStop)
            that.sendInfo.num1 = obj.houseTypeId
            that.sendInfo.hotelDiningId = item.chargeObjId
            that.liveKeyValueList[3].value = item.chargeName; // 入住酒店
            that.liveKeyValueList[4].value = item.checkTypeNumArticle; // 入住房型
            that.liveKeyValueList[5].value = obj.checkInDate // 住店日期
            that.liveKeyValueList[6].value = obj.checkInRequire;  // 入住要求
            that.sendInfo.roomEnergyFood = obj.roomNumber // 入住房间
          }
        })
      });
      /* 微信扫码登录后获取数据
      let paramsStr = location.search.split("&");
      let code = paramsStr[0].split("=")[1];
      let state = paramsStr[1].split("=")[1];
      let params = {code: code}
      wechatWhetherBindPhone(params).then(res => {
        that.$tool.resValidation(res, () => {
          console.log(res);
        })
      })*/
    },
    onSubmit(){
      let that = this;
      if(that.showType == 2) {
        if(!that.sendInfo.roomEnergyFood) {
          return that.$toast.fail('安排房间不能为空');
        }
      }
      if(!that.sendInfo.code) {
        return that.$toast.fail('验证码不能为空');
      }
      let str = that.chargePhone.substring(that.chargePhone.length-4);
      if(that.sendInfo.code != str){
        that.$toast('验证码有误！');
        return;
      }
      if(!that.sendInfo.handlePerson) {
        return that.$toast.fail('经办人不能为空');
      }
      if(!that.sendInfo.handlePhone) {
        return that.$toast.fail('联系电话不能为空');
      }
      energyWashRoomFoodRegister(that.sendInfo).then(res => {
        that.$tool.resValidation(res,() => {
          that.$router.push({
            path: `/par_success`,
            query: {
              type: that.showType
            }
          })
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>